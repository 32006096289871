@import-normalize;
/* @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=PT+Sans&display=swap');
html, body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", Arial, sans-serif;
  background-image: linear-gradient(#173049, #5E7180);
  background-attachment: fixed;
  /* overflow-x: hidden; */
  
}

/* @media (max-width: 350px) {
  html, body {
    font-size: 14px;
  }
} */

* {
  box-sizing: border-box;
}

/* 1464px for 5 wide course cards */
.app-container {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  max-width: 1200px;
  margin: 0; 
  background-color: white;
}

@media screen and (min-width: 1200px) {
  .app-container {
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
/* Temp Workaround */
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 0;
}
/* .fade-exit-active {
  opacity: 0;
  transition: opacity 12000ms;
} */